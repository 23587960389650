import React from 'react';
import {NavLink} from "react-bootstrap";
import Job from "../../components/job/job";
import {BuildingFill} from "react-bootstrap-icons";

class PointBlank extends Job{
    constructor(props) {
        super(props);
        this.techs = ["react","postgresql","vite","express","jest"]
        this.duties = [
            "Communicate design goals to team leads",
            "Participate in weekly progress meetings",
            "Advise on design goals",
            "Advise on Feature priority"
        ]
    }
    icon_mapper(tech){
        let prefix = tech;
        let postfix = "-plain";
        if(tech === "express")
            postfix="-original"
        else if (tech === "vite")
            postfix="js-plain"

        return prefix+postfix;
    }

    get_site_and_icon() {
        return (
            <div>
                <NavLink href={"https://" + this.props.site}> <BuildingFill/> {" " + this.props.site} </NavLink>
                <p> Demo available at <a className="text-bg-success" href="https://pblank.blewi.xyz">pblank.blewi.xyz</a></p>
            </div>
        )
    }

    get_description(){
        return(
            <p>
                A friend of mine who once worked in <a className="text-bg-success"
                href="https://www.cs.usask.ca/people/faculty%20profiles/nathaniel-osgood.php">Dr. Nathanial Osgood</a>'s lab
                mentioned that Dr.Osgood was looking for project ideas for his <a className="text-bg-success"
                href="https://catalogue.usask.ca/CMPT-371">CMPT 371 class</a>. I had just the thing! I took on the
                hobby of projectile point identification and cataloguing when I found my family's projectile point
                (arrowhead) collection jingling around in a ziplock bag. I wanted to know more about them, so I began
                documenting, photographing, and individually bagging each point in our collection. Currently, this
                catalogue is in the form of paper notes, a solution that did not scale well when my uncle asked me to
                catalogue his collection as well. As a technologist, I knew how I would create cataloguing software that
                fits my needs, but as a masters student I did not have the time to design/implement such a system. So
                this is the idea I pitched to the class: An open source cataloguing system for artifacts. I was
                delighted to learn not only that Dr.Osgood had some experience lithics, but that a team had decided
                to pick up the project. For the winter term of the 2024 academic year, I acted as the primary stakeholder
                along with my friends <a className="text-bg-success" href="https://goertzen.dev/">Jason Goertzen</a> (Mathematics,
                Computer Science) & <a className="text-bg-success" href="https://linkedin.com/in/gabriel-essaunce-lamarche-b12144130">
                Gabriel Lamarche</a> (Archaeology) to help the team actualize this project. Seeing as I planned on
                continuing work on the project once they had finished, the goals I gave the team were  A) to create a
                strong MVP capable of managing projectile points <i>specifically</i>. B) When designing the system and
                database layout, anticipate the need to catalogue other artifact types (Faunal, Ceramics, & other Lithic
                artifacts), and C) Pay special attention to developer documentation so I don't have to pester you when
                I inherit the project. Since the class has finished, I have forked the repository and have a live demo
                running at the URL mentioned above. The project has a long way to go, but I couldn't be happier with the
                team. They delivered a strong starting point for my vision and were a pleasure to work with.
                Their hard work is much appreciated.
            </p>
        )
    }
}

export default PointBlank