import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Image} from "react-bootstrap";
import {EnvelopeAtFill, Git, Github, Linkedin} from "react-bootstrap-icons"

class About extends Component {
    render() {
        return(
            <div className="text-bg-light">
            <Container fluid id="about">
                {/*Title of section  */}
                <Row>
                    <Col>
                        <h1 className="display-2">ABOUT</h1>
                    </Col>
                </Row>
               {/*Here goes the about me bit */}
                <Row className="row-cols-xxl">
                    <Col xl>
                        <Row>
                        {/*<h4 className="display-4"><u>Hello! I'm Ben!</u></h4>*/}
                        <p>
                            Fisherman, igloo constructor, and BSc.  of computer science. I graduated from the University of
                            Saskatchewan in 2022 and have been a masters student under Dr.Julita Vassileva in
                            the <a href="https://madmuc.usask.ca">MADMUC lab</a> ever since. I enjoy unique constraints
                            in my programming challenges and have a passion for researching the societal effects of
                            technology. I have a broad interest set ranging from bringing my code to life with
                            microcontrollers, to self-hosting open-source services. In my spare time I enjoy reading
                            world philosophy, kayaking, igloo building, and fishing.
                        </p>
                        </Row>
                        <Row>
                            <h6 className="display-6">Contact</h6>
                            <p>
                                <EnvelopeAtFill/> contact@blewi.xyz
                            </p>
                            <p>
                                <Git/> <a className="text-bg-light" href="https://git.cs.usask.ca/fbl773">git.cs.usask.ca/fbl773</a> <b>(School)</b>
                            </p>
                            <p>
                                <Github/> <a className="text-bg-light" href="https://github.com/fbl773">github.com/fbl773</a> <b>(School)</b >
                            </p>
                            <p>
                                <Git/> <a className="text-bg-light" href="https://git.blewi.xyz/FrankyB">git.blewi.xyz/FrankyB</a> <b>(Personal)</b >
                            </p>
                            <p>
                                <Linkedin/> <a className="text-bg-light" href="https://www.linkedin.com/in/bennett-lewis-829074176">Bennett Lewis</a>
                            </p>
                        </Row>
                    </Col>
                    {/*And here goes the photo*/}
                    <Col sm={4} className="justify-content-end text-sm-start">
                        <Image src="img/about/fishing_2.jpeg" alt="wat?" />
                        <p>Photo credit A.P. | Gem Lakes Saskatchewan 2020</p>
                    </Col>
                </Row>
            </Container>
                </div>
        )
    }
}

export default About;
