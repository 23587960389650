import React from 'react';
import Publication from "../../components/publication";
class CHI2024 extends Publication{
    get_abstract(){
        return(
            <div className="border border-3 p-3 rounded-2">
                <h2>Abstract:</h2>
                <p>
                    The issue of Dark Patterns or "Deceptive Design" is becoming more apparent, and more widely ac-
                    knowledged in literature. However, their ubiquity across domains has complicated interdisciplinary
                    communication and collaborative efforts concerning their nature and effects. Existing taxonomies de-
                    scribing these patterns contain a considerable amount of overlap and address patterns at varying levels
                    of abstraction further complicating the matter of cross-domain discourse. This becomes problematic
                    given the growing evidence supporting the adverse effects such design can have on users. Further
                    complicating the issue of Dark Patterns is the thin line that separates their implementations from those
                    of intuitive, protective, and defensive interface design patterns. Existing taxonomies focus primarily
                    on pattern definition and it is challenging to make this distinction when applying them in specific
                    contexts. This work proposes a method of discerning manipulative, from benevolent applications of
                    similar design principles by analyzing previously identified patterns for their properties, consequences,
                    and contexts of application. This paper represents the progress we have made thus far to the creation of
                    a taxonomy-independent evaluation process for the identification and description of Dark Patterns.
                </p>
            </div>
        )
    }
}

export default CHI2024;