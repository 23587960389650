import Project from "../../components/project/project";
import React from "react";
import {Carousel,Image} from "react-bootstrap";

class Project_arduwatch extends Project{

    constructor(props) {
        super(props);
        this.images = ["picool1.jpg","picool2.jpg","picool3.jpg"];
    }

   get_images() {
        return(
           <Carousel>
               {this.images.map((img_name) => {
                   return(
                       <Carousel.Item>
                           <Image
                               className="d-block"
                               style={{marginLeft:"auto",marginRight:"auto"}}
                               src={"img/projects/picool/"+img_name}
                               alt={img_name}
                           />
                       </Carousel.Item>
                   )
               })}
           </Carousel>
       )
   }

    get_description() {
        return(
            <p>
                Picool was originally a python script that I had written run the fans on my raspberry pis only
                once they reach a certain temperature (rather than all the time). After writing up my
                initial solution for my pi running raspbian, I quickly found that the python library I used did to
                control GPIO not work on my raspi running ubuntu server... so I configured it to use the very
                linux-y quality of everything being a file via <a className={"link-light"}  href="https://dev.to/tardisgallifrey/raspberry-pi-gpio-4-ways-45do">this solution</a>.
                After awhile I figured that python was a bit bulky of a language for such a task so I converted the
                solution to c and introduced a modest config file. It also gave me a chance to practice my test-writing
                skills in c using asserts and shell scripting.
            </p>
        )
    }

    get_challenge() {
        return(
            <ul>
               <li>
                   <p>
                   <b>OS Independence:</b> Because my original solution relied on a python GPIO library that wouldn't
                       work for ubuntu server, I dug around a bit and found this <a className="link-light" href="https://dev.to/tardisgallifrey/raspberry-pi-gpio-4-ways-45do">
                       dev.to post</a> which revealed that in a very true-to-linux fashion, GPIO on raspi's is simply
                       a series of file I/O operations. The first part of this challenge involved converting the whole
                       thing to use c over python (a more appropriate language for system-level coding), the second part
                       was pretty simple - control I/O to the appropriate GPIO files. It is a simple problem, so I guess
                       it has simple challenges.
                   </p>
               </li>
               <li>
                  <p>
                      <b><i>TODO</i> apt-get repo:</b> The next part of the challenge is to make this solution avialable
                      via apt-get, It is annoying to have to pull the repo and make the project every time one of my pi's
                      gets reset. Running the makefile is seldom a users first choice.
                  </p>
               </li>
           </ul>
        )
    }

    icon_mapper(tech){
        let postfix =  "-plain";
        let prefix = tech
        if(tech === "linux-services")
            prefix = "linux"
        if(tech === "raspberry pi"){
            prefix = "raspberrypi";
            postfix="-line";
        }

        return prefix + postfix;
    }
}

export default Project_arduwatch;