import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {ListGroup, ListGroupItem} from "react-bootstrap";

class Publication extends Component{

    constructor(props) {
        super(props);
        this.authors = ["AUTHOR1","AUTHOR2"]
    }

    get_abstract(){
        return(
            <div className="border border-3 p-3 rounded-2">
            <h2>Abstract:</h2>
            <p>
                I had a dream we were standing in a grand ovation and computers played the lord in a play about creation.
                I was born in a house but I live in this world and for now, its the best place we've got. Yeah for now
                its the best place we've got. And elation by space can replace gravitation levetation is made out of
                jet fuel and patience and the words we conserve can create frustaration usurpt by the herds and the
                heads of our nations. Procreation is a station? A hatian? libation? location location location location!
                I remember a time late in spring we went to the museum slow, I said dinosaurs look sad, you said, "just
                stop thinking". I wrote the use about our planet, not exploding love (an elusive element on earth
                periodic) - JUST STOP THINKING! OH! I just want to know. At the end of time and space, who'll remember
                the human race? All that fortune and all tha fame? Who'll remember the fun we had?
            </p>
            </div>
        )
    }

    get_document(){
        return(
                <object data={this.props.doc} type="application/pdf" width="100%" height="100%">
                </object>
        )

    }

    render(){
        return(
            <div id={this.props.id} className="text-bg-primary border border-3 border-top border-bottom-0
            border-end-0 border-start-0 p-4">
                <Container fluid>
                    <Row className="justify-content-lg-end row-cols-1 text-lg-end p-4">
                        <Col>
                            <h1 className="display-4">publications/{this.props.label}</h1>
                        </Col>
                    </Row>
                    <Row className="row-cols-lg-2 row-cols-1 p-4">
                        <Col>
                            {this.props.doc === undefined ?
                                <h2>Preview unavailable</h2> :
                                this.get_document()
                            }
                        </Col>
                        <Col >
                            {/*    Data goes here*/}
                            <Row className="p-2">
                                <Col>
                                    <a className="link-light" href={this.props.link}>{this.props.journal}</a> - {this.props.year}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <ListGroup horizontal={"lg"} className="border border-2 rounded-2">
                                    <ListGroupItem>Authors:</ListGroupItem>
                                {this.props.authors !== undefined ? this.props.authors.map((author) => {
                                return(
                                    <ListGroupItem
                                        className="bg-secondary text-bg-secondary
                                        border-1 border-end border-start-0 border-top-0 border-bottom-0">
                                        {author}
                                    </ListGroupItem>
                                )}
                                ) : <ListGroupItem> {"NO AUTHORS"} </ListGroupItem>}
                                </ListGroup>
                                </Col>
                            </Row>
                           <Row className="pt-3">
                               <Col>
                                   {this.get_abstract()}
                               </Col>
                           </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Publication;