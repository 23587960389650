import React from 'react';
import Publication from "../../components/publication";
class Pubnisotak extends Publication{
    get_abstract(){
        return(
            <div className="border border-3 p-3 rounded-2">
                <h2>Abstract:</h2>
                <p>
                    This paper outlines the design, development, and preliminary usability study of a system comprising
                    1) a web-based Indigenous lesson-creation interface and 2) an accompanying mobile app for studying
                    the lessons. The Nisotak project was developed in response to the need for the preservation of
                    Indigenous languages and to support reconciliation within Canada. In this paper, the authors discuss
                    the technological aspects of the project and the less tangible decision-making that helped navigate
                    software development in ways that support and honour Indigenous languages, Indigenous knowledge, and
                    Indigenous people while, at the same time, making space for non-Indigenous allies. The key decisions
                    that guided this project included privileging the target language(s), accommodating multiple
                    dialects, creating an easy-to-use and engaging interface for non-technical users, and designing for
                    easy transfer of ownership and management. Finally, the authors share the results of a small
                    usability study.

                </p>
            </div>
        )
    }
}

export default Pubnisotak;