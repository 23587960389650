import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Carousel, ListGroupItem, ListGroup, NavLink} from "react-bootstrap";
import {Git} from "react-bootstrap-icons";

class Project extends Component {

    //Could alternatively use a dictionary... Though I like the inheritablility of the function if we decide to
    //split each project into their own component down the road... Actually, then they wouldn't need to
    //override the get_tech function, they could just override this. I think that sounds like a plan.
    icon_mapper(tech) {
        let prefix = tech;
        let postfix = "-plain";
        if (tech === "c++")
            prefix = "cplusplus"
        else if (tech === "c#")
            prefix = "csharp"
        else if (tech === "dotnet core")
            prefix = "dotnetcore"
        else if (tech === "linux-services")
            prefix = "linux"
        else if (tech === "golang")
            prefix = "go"
        else if (tech === "raspberrypi") {
            prefix = "raspberrypi"
            postfix = "-line"
        }
        return prefix + postfix
    }

    get_description() {
        return (
            <p>
                {this.props.description}
            </p>
        )
    }

    get_techs(techs) {
        return (
            techs.sort().map((tech) => {
                return (
                    <ListGroupItem className="bg-light">
                        <i className={"devicon-" + this.icon_mapper(tech)}></i>
                        {" " + tech}
                    </ListGroupItem>
                )
            })
        )
    }

    get_challenge() {
        return (
            <p>
                This is where my challenge would go
            </p>
        )
    }

    get_images() {
        return (
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="holder.js/800x400?text=First slide&bg=373940"
                        alt="First slide"
                    />
                    <Carousel.Caption>
                        <h3>First slide label</h3>
                        <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        )
    }

    render() {
        return (
            <div id={this.props.proj_name} className="text-bg-secondary
        border border-3 border-top border-bottom-0 border-end-0 border-start-0
        p-4">

                <Container fluid>
                    {/*{Title}*/}
                    <Row>
                        <Col className="col-sm-1">
                            <h1 className="display-4">projects/{this.props.proj_name}</h1>
                        </Col>
                    </Row>
                    {/*{Here goes the link}*/}
                    <Row>
                        <Col>
                            <NavLink href={"https://" + this.props.repo}>
                                <Git/>
                                {" " + this.props.repo}
                            </NavLink>
                            {/*What is this project about anyway?*/}
                            <Row>
                                <h4>Description:</h4>
                                {this.get_description()}
                            </Row>
                            <Row className="border border-2">
                                <Col className="text-bg-light">
                                    <h4>Technologies:</h4>
                                    <ListGroup variant="flush">
                                        {this.get_techs(this.props.techs)}
                                    </ListGroup>
                                </Col>
                                <Col className="text-bg-secondary">
                                    <h4>Challenges:</h4>
                                    {this.get_challenge()}
                                </Col>
                            </Row>
                        </Col>
                        {/*Put the Media in the carosel*/}
                        <Col sm={4} lg={6} md={4} className={"justify-content-end text-sm-start"}>
                            {this.get_images()}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Project;