import React from 'react';
import {NavLink} from "react-bootstrap";
import Job from "../../components/job/job";
import {Wifi} from "react-bootstrap-icons";

class nisotak extends Job{
    constructor(props) {
        super(props);
        this.techs = ["angular","mongodb","ionic","react","ios","android","mysql","docker","sqlite","jest","express"]
        this.duties = [
            "Work remotely with a small team to develop a mobile application that facilitates indigenous language instruction",
            "Work remotely with a small team to develop a web application that for language experts to craft interactive lessons in their native language",
            "Work with native speakers to create a system to facilitate Indigenous language instruction",
            "Design and implement interactive components that facilitate learning",
            "Coordinate with a small team to identify and address bugs and inconsistencies within the application",
            "Produce monthly reports on work items addressed within sprints",
            "Create and execute a plan for release on both iOS and Android platforms",
            "Identify and alleviate bottlenecks in the release process",
            "Evaluate and improve on current software-testing standards",
            "Implement a UI that can be navigated in multiple, user-defined, languages"
        ]
    }
    get_description(){
        return(
            <p>
                The word <i>nisotak</i> is intentionally left as lowercase. This is a reflection of the nêhiyawêwin (Cree)
                philosophy that no word/letter is any greater than another. The word itself is an old way of referring to
                "two canoes" and also serves as the acronym "nêhiyawêwin instructional syntax online for teaching and
                knowledge". I was asked to join the nisotak project after being noticed by one of my CMPT 412
                professor at the university. It was the first term out of Delco and I'd been practicing what I learned
                there on my personal projects but was eager to work with a team and apply my skills at scale again. When
                I first entered the project, the original authors were long gone and had left user-facing PDFs as the only
                technical documentation of their work, our release process was completely manual and included only
                android, testing was non-existent, many features were only partially implemented and seemingly abandon
                while others were present, but lacked generalizability and were full of bugs. The nisotak project still
                has a long way to go, but after roughly a year of part-time work we automated our build process,
                wrote and automated testing for most system components, cleaned up our bugs, cleaned up our UI,
                completed our API's conversion from Linux-services to docker, implemented critical features, and greatly
                improved our user experience. Future developers now have extensive external and internal documentation
                in the form of git-wiki's, postman API docs, and typedoc/jsdoc self-generated documentation. Unfortunately,
                the project ran out of funding and our Google Play/Apple Developer licenses have expired so it is no
                longer available on either store as of 2024. The nisotak project gave me a low-stakes environment to
                develop my software engineering skills and I couldn't be more grateful for the professional development
                opportunity that it gave me.
            </p>
        )
    }

    icon_mapper(tech){
        let prefix = tech;
        let postfix = "-plain";
        if(tech === "ionic")
            postfix = "-original"
        else if (tech === "ios")
            prefix = "apple"
        else if(tech === "angular")
            prefix = "angularjs"
        else if(tech === "express")
            postfix="-original"

        return prefix+postfix;
    }

    get_site_and_icon() {
        return(
            <NavLink href={"https://" + this.props.site}>
                <Wifi/>
                {" "+this.props.site}
            </NavLink>
        )
    }
}

export default nisotak