import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import Delco from "./delco";
import {default as Nisotak} from "./nisotak"
import PointBlank from "./pointb_ank";


class Experience extends Component{

    render() {
        return (
            <div id="experience" className="text-bg-success">
                <Container fluid>
                    <Row className="justify-content-end row-cols-1 text-end p-4">
                        <Col>
                            <h1 className="display-2">EXPERIENCE</h1>
                        </Col>
                    </Row>
                    <Row className="row-cols-lg-3 text-end justify-content-end p-4 text-bg-success">
                        <Col>
                            <ListGroup className="text-bg-secondary">
                                <ListGroupItem className="text-bg-secondary" action href="#point_blank">Point B_ank</ListGroupItem>
                                <ListGroupItem className="text-bg-secondary" action href="#nisotak">nisotak</ListGroupItem>
                                <ListGroupItem className="text-bg-secondary" action href="#delco">Delco Automation</ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <PointBlank
                    job_id="point_blank"
                    timeline="Winter 2024"
                    job_name="Point B_ank"
                    position="Primary Stakeholder"
                    site="github.com/UniversityOfSaskatchewanCMPT371/term-project-2024-team-4"
                />
                <Nisotak
                    job_id="nisotak"
                    timeline="2021-2022"
                    job_name ="nisotak"
                    position="Lead Developer"
                    site="nisotak.usask.ca"
                />
                <Delco
                    job_id="delco"
                    timeline="2019 - 2020"
                    job_name="Delco Automation"
                    position="Jr. Developer Intern"
                    site="ipfusion.ca"
                />
            </div>
        )
    }
}
export default Experience;
