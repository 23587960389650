import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import Machete from "./pub_machete";
import Pubnisotak from "./pub_nisotak";
import CHI2024 from "./pub_chi_dps";


class Publications extends Component{
    render() {
        return (
            <div id="publications" className="text-bg-primary">
                <Container fluid>
                    <Row className="justify-content-end row-cols-1 text-end p-4">
                        <Col>
                            <h1 className="display-2">PUBLICATIONS</h1>
                        </Col>
                    </Row>
                    <Row className="row-cols-lg-3 text-end justify-content-end p-4 text-bg-primary">
                        <Col>
                            <ListGroup className="text-bg-info">
                                <ListGroupItem className="text-bg-secondary" action href="#pub_chi_2024">Seeing in the Dark</ListGroupItem>
                                <ListGroupItem className="text-bg-secondary" action href="#pub_nisotak">nisotak</ListGroupItem>
                                <ListGroupItem className="text-bg-secondary" action href="#pub_machete">Project Machete</ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <CHI2024
                    id={"pub_chi_2024"}
                    year={2024}
                    authors={["F. Bennett W. Lewis","Julita Vassileva"]}
                    journal={"DDPCHI2024: Mobilizing Research & Regulatory Action on Dark Pattern & Deceptive Design Practices"}
                    label={"Seeing in the Dark"}
                    title={"Seeing in the Dark: Revealing the Relationships, Goals, & Harms of Dark Patterns"}
                    link={"https://ceur-ws.org/Vol-3720/"}
                    doc_link={"https://ceur-ws.org/Vol-3720/paper8.pdf"}
                    doc={"doc/chi_2024_dp.pdf"}
                />

                <Pubnisotak
                    id={"pub_nisotak"}
                    year={2022}
                    authors={["Margurite Koole","Randy Morin","Kevin Lewis","Kristine Dreaver-Charles",
                        "Ralph Deters","Julita Vassileva","Frank B. W. Lewis"]}
                    journal={"International Journal of Mobile & Blended Learning"}
                    link={"https://www.igi-global.com/article/nisotak/318262"}
                    doc_link={"https://www.igi-global.com/pdf.aspx?tid=318262&ptid=318222&ctid=4&oa=true&isxn=9781668492062"}
                    label={"nisotak"}
                    title={"Nisotak: An Example of Flexible Design for Indigenous Language Learning Apps and Reconciliation"}
                    doc={"doc/pub_nisotak.pdf"}
                />

                <Machete
                    id={"pub_machete"}
                    year={2021}
                    authors={["F. Bennett W. Lewis"]}
                    journal={"International Conference on Behavior Change Support Systems"}
                    label={"Project-Machete"}
                    title={"Project Machete: A Weapon to Cut Through The Amazon(.ca)"}
                    link={"https://bcssworkshop.wordpress.com/programme-2021/"}
                    doc_link={"https://bcssworkshop.files.wordpress.com/2021/04/lewis.pdf"}
                    doc={"doc/project_machete_submission.pdf"}
                />
            </div>
        )
    }
}
export default Publications;
