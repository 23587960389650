import React from 'react';
import Job from "../../components/job/job";

class Delco extends Job{
    constructor(props) {
        super(props);
        this.techs = ["dotnet","c#","visual studio","dotnet core"]
        this.duties = [
            "Attend daily standup meetings","Attend sprint planning meetings",
            "Self-assign and address bugs",
            "Collaborate with team to introduce new integrations",
            "Self-assign and implement tasks",
            "Write integration test suites via moq",
            "Perform preliminary usability tests"
        ]
    }
    get_description(){
        return(
            <p>
                As part of The University of Saskatchewan's Computer Science <a className="text-bg-success" href="https://www.cs.usask.ca/students/undergraduate/become-an-intern.php">
               Professional Internship Program</a> (CSPIP) I completed a 16 month internship at Delco Automation in the
               Technology divison working on their IPFusion product. I was a part of the "Integration Team" which handled
               writing adapters for various door, camera, and other access-control system vendor's APIs such that they
               could be interfaced with through the IPFusion platform. Interacting with so many APIs gave me a sense of
               what a good API looks like which came in handy later while working on the nisotak project.
               Occasionally our team would assist the IPFusion team which handled the user facing interactions with the
               APIs we integrated. It was at Delco that I was first exposed to an enterprise level git environment and
               became familiar with agile development, SCRUM, and Devops - skills I now take with me to every project
               I am involved with.
            </p>
        )
    }

    icon_mapper(tech){
        let postfix =  "-plain";
        let prefix = tech
        if(tech === "c#")
            prefix = "csharp"
        else if(tech === "dotnet core")
            prefix = "dotnetcore"
        else if(tech === "dotnet")
            prefix = "dot-net"
        else if(tech === "visual studio")
            prefix = "visualstudio"
        return prefix + postfix;
    }
}

export default Delco