import React from 'react';
import Publication from "../../components/publication";
class Machete extends Publication{
    get_abstract(){
        return(
            <div className="border border-3 p-3 rounded-2">
                <h2>Abstract:</h2>
                <p>
                As persuasive systems seep into our lives on the back of technology, the need for counter-persuasive
                technologies becomes more apparent. Many users are not aware of design elements that exploit their
                cognitive biases and encourage them to act against their own self-interests; Project-Machete was
                created to level the playing field. It seeks to limit the ability of persuasive technologies on the
                Amazon shopping platform to persuade users into making impulsive or extrinsically influenced
                purchases. It removes biased plays to item scarcity, selectively reveals recommended items, counters
                 biased encouragement to buy new over used items, and encourages shoppers to think critically about
                whether a purchase should be made at all. Project-Machete achieves this through JavaScript driven
                DOM-tree manipulation inside an extension for the Google Chrome web browser.
                </p>
            </div>
        )
    }
}

export default Machete;