import React, {Component} from 'react';
import "./navburger.css"
import {
    Nav,
    Navbar,
    NavDropdown,
    OffcanvasBody,
} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";

class NavBurger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
    }

    toggle_active(){
        let toggled = !this.state.active
        this.setState({active:toggled})
    }

    render() {
        return (
            <Navbar collapseOnSelect
                    variant="light"
                    expand={false}
                    fixed="top"
                    className="bg-transparent"
                    onToggle={() => {this.toggle_active()}}>
                <Container fluid className="bg-transparent">
                    <Navbar.Toggle
                        hidden={this.state.active}
                        aria-controls="responsive-navbar-nav" />
                    <NavbarCollapse id="responsive-navbar-nav" className="bg-opacity-75 bg-light">
                            <OffcanvasBody>
                                <Nav className="justify-content-end flex-grow-1 pe-3 burger-nav"
                                     variant="tabs"
                                     activeKey=""
                                >
                                    <Nav.Link  href="#home">Home</Nav.Link>
                                    <Nav.Link href="#about">About</Nav.Link>
                                    {/*TODO: THis could be programatically genrated*/}
                                    <NavDropdown title="Experience">
                                        <NavDropdown.Item href="#point_blank">Point B_ank</NavDropdown.Item>
                                        <NavDropdown.Item href="#nisotak">nisotak</NavDropdown.Item>
                                        <NavDropdown.Item href="#delco">Delco Technology</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="Projects">
                                        <NavDropdown.Item href="#arduwatch">arduwatch</NavDropdown.Item>
                                        <NavDropdown.Item href="#arduweather">arduweather</NavDropdown.Item>
                                        <NavDropdown.Item href="#picool">picool</NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="Publications">
                                        <NavDropdown.Item href="#pub_chi_2024">Seeing in the Dark</NavDropdown.Item>
                                        <NavDropdown.Item href="#pub_nisotak">nisotak</NavDropdown.Item>
                                        <NavDropdown.Item href="#pub_machete">Project Machete</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            </OffcanvasBody>
                    </NavbarCollapse>
                </Container>
            </Navbar>
        )
    }
}

export default NavBurger;
