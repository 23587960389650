import Project from "../../components/project/project";
import React from "react";
import {Carousel,Image} from "react-bootstrap";

class Project_arduweather extends Project{
    constructor(props) {
        super(props);
        this.images = ["arduweather_hardware_1.jpg","arduweather_hardware_2.jpg",
            "arduweather_hardware_3.jpg", "arduweather_screenshot.png"]
    }

    get_description() {
        return(
            <p>
                Arduweather was a project that I undertook to practice my dotnet skills for
                delco. I've always wanted to be able to check when it was nice enough to sit on the
                deck for some iced tea so I threw together some of the arduino environmental monitoring kit
                that I got from a friend, a quick mongodb to track the data, and a modest dotnet web-app to
                display interface with the db and display logged data with google charts. I got tired
                of setting up the dotnet runtime on my pi's, and a friend wanted to see if I could use the
                system to diagnose the cooling cycle in his fridge so I decided to dockerize the whole thing
                later on. Now I just need to beef up my hardware skills so that the thing doesn't look so ugly!
                <br/>
                <br/>
                <b><s>You can check the weather on my deck yourself <a className="text-bg-secondary" href={"/weather"}>here!</a></s></b> <b> CLOSED FOR THE WINTER</b>
            </p>
        )
    }

    get_images(){
        return(
           <Carousel>
               {this.images.map((img_name) => {
                   return(
                       <Carousel.Item>
                           <Image
                               className="d-grid"
                               style={{marginLeft:"auto",marginRight:"auto"}}
                               src={"img/projects/weather/"+img_name}
                               alt={img_name}
                           />
                       </Carousel.Item>
                   )
               })}
                   {/*<Carousel.Caption>*/}
                   {/*    <h3>First slide label</h3>*/}
                   {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                   {/*</Carousel.Caption>*/}
           </Carousel>
       )
    }

    get_challenge() {
        return(
            <ul>
               <li>
                   <p>
                   <b>Configurable Google Charts:</b> I wanted to be able to select which of the metrics I would use
                       to build my google charts (temperature, humidity, wind-speed, etc.) without having to manage
                       some array of booleans. So I used this trick I learned at <a className={"link-light"} href={"#delco"}>Delco</a> where set each datapoint
                       as an <a className={"link-light"} href="https://git.blewi.xyz/FrankyB/arduweather/-/blob/master/Dashboard/ADWDBCore/DBManager/DBConstants.cs#L11">
                       enum value</a> that is a power of 2, then I set each checkbox value for the ones I would like to
                       display <a className={"link-light"} href="https://git.blewi.xyz/FrankyB/arduweather/-/blob/master/Dashboard/ADWDBCore/DBManager/WeatherEntry.cs#L82">
                       as their corresponding power of 2</a>, combining them with <a className={"link-light"} href="https://git.blewi.xyz/FrankyB/arduweather/-/blob/master/Dashboard/ADWDBCore/ADWDBCore/Controllers/HomeController.cs#L78">
                       binary OR</a> and then <a className={"link-light"} href="https://git.blewi.xyz/FrankyB/arduweather/-/blob/master/Dashboard/ADWDBCore/DBManager/DBManager_Mongo.cs#L212">
                       checking each the flags set</a> on the result when constructing my chart.
                   </p>
               </li>
               <li>
                   <p>
                       <b><i>TODO</i> Fix Instrument Precision:</b> the accuracy of the floating points displayed on the screen are
                       far greater than what I am measuring them with... easy fix.
                   </p>
               </li>
                <li>
                   <p>
                       <b><i>TODO</i> Hook-up anemometer:</b> I have a fan that <i>should</i> be able to generate an analog value
                       for wind speed. It would need to be calibrated of course, but we can't really display wind speed / direction
                       truthfully without it.
                   </p>
               </li>
           </ul>
        )

    }

    icon_mapper(tech){
        let postfix =  "-plain";
        let prefix = tech
        if(tech === "c#")
            prefix = "csharp"
        else if(tech === "dotnet core")
            prefix = "dotnetcore"
        else if(tech === "golang")
            prefix = "go"
        return prefix + postfix;
    }
}

export default Project_arduweather;