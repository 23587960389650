import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import './landing.scss';
import {Navbar, Nav} from "react-bootstrap";

class Landing extends Component {
    render() {
        return (
            <div id="home">
                <Row className="text-end text-secondary">
                    <h6>photo credit: K.L. | <i>Dutch Creek</i></h6>
                </Row>
                <Container id="title" fluid={"sm"}>
                    <Row className="text-center">
                        <Col>
                            <h1 className="display-1">
                                <b>Bennett Lewis</b>
                            </h1>
                        </Col>
                    </Row>
                    <Row className="text-center text-secondary">
                        <Col className="col-sm-3"/>
                        <Col>
                            <h6>
                                <i>BSc Computer Science</i>
                            </h6>
                        </Col>
                    </Row>
                    {/*576 is what bootstrap considers "small"*/}
                    {window.innerWidth >= 576 &&
                        <Row className="justify-content-center">
                            <Navbar inverse collapseOnSelect bg="secondary" expand={"lg"} variant="light"
                                    className="justify-content-center">
                                <Navbar.Toggle/>
                                <Navbar.Collapse className={"justify-content-center"}>

                                    <Nav className="justify-content-center">
                                        <Nav.Link href="#home" active={true}>Home</Nav.Link>
                                        <Nav.Link href="#about">About</Nav.Link>
                                        <Nav.Link href="#experience">Experience</Nav.Link>
                                        <Nav.Link href="#projects">Projects</Nav.Link>
                                        <Nav.Link href="#publications">Publications</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Row>
                    }
                </Container>
            </div>
        )
    }
}

export default Landing;
