import React, {Component} from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {ListGroup, ListGroupItem, NavLink} from "react-bootstrap";
import {BuildingFill} from "react-bootstrap-icons";

class Job extends Component {
    constructor(props) {
        super(props);
        this.duties = ["EXAMPLE DUTY"]
        this.techs = ["EXAMPLE TECH"]
    }

    icon_mapper(tech) {
        let postfix = "-plain";
        return tech + postfix;
    }

    get_description() {
        return (
            <p>
                hey, I'm a description.
            </p>
        )
    }

    get_duties() {
        return (
            this.duties.map((duty) => {
                return (
                    <ListGroupItem className="text-bg-secondary border-0">
                        <b>• </b>{duty}
                        {/*    We have fudged it here for now with the bullet character, but TODO: Issue #9 tracks this*/}
                    </ListGroupItem>
                )
            })
        )
    }

    get_techs() {
        return (
            this.techs.sort().map((tech) => {
                return (
                    <ListGroupItem className="bg-light">
                        <i className={"devicon-" + this.icon_mapper(tech)}></i>
                        {" " + tech}
                    </ListGroupItem>
                )
            })
        )
    }

    get_site_and_icon() {
        return (
            <NavLink href={"https://" + this.props.site}>
                <BuildingFill/>
                {" " + this.props.site}
            </NavLink>
        )
    }

    render() {
        return (
            <div id={this.props.job_id} className="text-bg-success border border-3 border-top border-bottom-0
            border-end-0 border-start-0 p-4">
                <Container fluid>
                    {/*{Title}*/}
                    <Row>
                        <h1 className="display-4">experience/{this.props.job_name}</h1>
                    </Row>
                    {/*{Here goes the link}*/}
                    <Row>
                        <Col>
                            {this.get_site_and_icon()}
                            {/*What is this project about anyway?*/}
                            {/*<Row className="row-cols-xxl">*/}
                            <h4>Position:{" " + this.props.position}</h4>
                            <i>{this.props.timeline}</i>
                            <Row>
                                <Col>
                                    <h4>Description:</h4>
                                    {this.get_description()}
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Container className={"text-bg-secondary border border-2"}>
                            <Row className={"text-bg-secondary"}>
                                <h4>Duties:</h4>
                                <ListGroup className="border-0">
                                    {this.get_duties()}
                                </ListGroup>
                            </Row>
                            <Row className="text-bg-light">
                                <h4>Technologies:</h4>
                                <ListGroup>
                                    {this.get_techs()}
                                </ListGroup>
                            </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Job