import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {ListGroup, ListGroupItem} from "react-bootstrap";
import {default as ArduWeather } from "./project_arduweather";
import {default as ArduWatch} from "./project_arduwatch";
import {default as PiCool} from "./project_picool";


class Projects extends Component{
    render() {
        return (
            <div id="projects" className="text-bg-secondary">
                <Container fluid>
                    <Row className="justify-content-end text-start p-4">
                        <Col>
                            <h1 className="display-2">PROJECTS</h1>
                        </Col>
                    </Row>
                    <Row className="p-4 row-cols-lg-3">
                        <Col>
                            <ListGroup>
                                <ListGroupItem action href="#arduwatch">arduwatch</ListGroupItem>
                                <ListGroupItem action href="#arduweather">arduweather</ListGroupItem>
                                <ListGroupItem action href="#picool">picool</ListGroupItem>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
                <ArduWatch
                         proj_name="arduwatch"
                         repo="git.blewi.xyz/FrankyB/arduwatch"
                         techs={["c++","arduino","neopixels"]}
                />
                <ArduWeather
                    proj_name="arduweather"
                    techs={["docker","mongodb","dotnet core","arduino","c#","golang"]}
                    repo="git.blewi.xyz/FrankyB/arduweather"
                />
                <PiCool
                        proj_name="picool"
                         repo="git.blewi.xyz/FrankyB/picool"
                         techs={["c","linux-services","make","raspberry pi","bash","ubuntu"]}
                />
            </div>
        )
    }
}
export default Projects;
