import Project from "../../components/project/project";
import React from "react";
import {Carousel,Image} from "react-bootstrap";

class Project_arduwatch extends Project{
    constructor(props) {
        super(props);
        this.images = ["watch_2.jpg","watch_1.jpg","watch_3.jpg"]
    }

    get_description() {
        return(
            <p>
                Arduwatch was my first ever side project. I got a free arduino by attending a tutorial for a
                student group on campus, it was an adafruit trinket 5v. I really enjoy the <i>idea</i> of wearing
                a watch, but hate replacing the battery of a conventional wristwatch and dislike the idea of having
                my biometrics collected by smartwatchs (I also hate being bugged by notifications).
                So I figured I would try to build my own!
                Using a <a className={"link-light"} href="https://learn.adafruit.com/adafruit-neopixel-uberguide/neopixel-rings"> neopixel ring</a> and
                the <a className={"link-light"} href={"https://learn.adafruit.com/adafruit-ultimate-gps/overview"}>Ultimate GPS v2</a> I managed to
                get a watch that displayed hour/minute/second , compass bearing, and speed in km/h
                on a ring of 12 neopixels (which was a fun challenge!). Sadly, I've reached the limitations of my
                hardware/3D modeling skills so the project remains on my breadboard, additionally, the GPS module has met
                its end. Perhaps it will make a nice office clock one day.
            </p>
        )
    }

    get_challenge() {
       return(
           <ul>
               <li>
                   <p>
                   <b>Representing minutes:</b> Representing individual minutes on a clock with only 12 hand-positions
                       was a fun challenge. I ended up solving it by creating a modifier from the current minute and
                       using it to <a className={"link-light"} href="https://git.blewi.xyz/FrankyB/ArduWatch/-/blob/master/lib/ADWatch_Functions/Watch_Clock/src/Clock_Face.cpp#L40">
                       modify the colour of the minute-hand</a>.
                   </p>
               </li>
               <li>
                   <p>
                       <b>Handling Hand-Crossover</b>: Because I couldn't lean on hand length to handle the scenario
                       when hands occupy the same value (i.e. 10:10, 3:15), I decided to have a location occupied by
                       multiple hands become the <a className={"link-light"} href="https://git.blewi.xyz/FrankyB/ArduWatch/-/blob/master/lib/ADWatch_Functions/Watch_Clock/src/Clock.cpp#L41">
                       average RGB value of each of the hands</a>. This also looks super cool when paired with the
                       modified minute hand effect.
                   </p>
               </li>
           </ul>
       )
    }

   get_images(){
        return(
           <Carousel>
               {this.images.map((img_name) => {
                   return(
                       <Carousel.Item>
                           <Image
                               className="d-block"
                               style={{marginLeft:"auto",marginRight:"auto"}}
                               src={"img/projects/watch/"+img_name}
                               alt={img_name}
                           />
                       </Carousel.Item>
                   )
               })}
                   {/*<Carousel.Caption>*/}
                   {/*    <h3>First slide label</h3>*/}
                   {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                   {/*</Carousel.Caption>*/}
           </Carousel>
       )
   }

    icon_mapper(tech){
        let postfix =  "-plain";
        let prefix = tech
        if(tech === "c++")
            prefix = "cplusplus"
        return prefix + postfix;
    }
}

export default Project_arduwatch;