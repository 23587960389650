import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Landing from "./pages/landing/landing";
import Projects from "./pages/projects/projects";
import About from "./pages/about/about";
import Navburger from "./components/navburger/navburger";
import {CCircle} from "react-bootstrap-icons";
import Experience from "./pages/experience/experience";
import Publications from "./pages/publications/publications";
import './custom.scss';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Navburger/>
      <link rel="stylesheet" type='text/css' href="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/devicon.min.css" />
      <Landing/>
    <About/>
    <Experience/>
    <Projects/>
    <Publications/>
      <footer>
          <p className="align-items-end">
            <CCircle/> Bennett Lewis 2023
          </p>
      </footer>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
